<template>
  <div>
    <!-- dialog -->
    <!-- dialog -->
    <!-- top -->

    <van-popup v-model="selectBSubShow" position="bottom">
      <van-picker
        title="选择渠道"
        show-toolbar
        :columns="[
          { text: '全部', value: false },
          { text: '银联', value: 'bank' },
          { text: '支付宝', value: 'alipay' },
          { text: '支付宝红包', value: 'alipay-hb' },
          { text: '数字人民币', value: 'ecny' },
          { text: '微信', value: 'wechat' },
          { text: '抖音红包', value: 'titok' },
        ]"
        @confirm="selectBank"
        @cancel="selectSubShow = false"
        position="bottom"
        cancel-button-text="取消"
        confirm-button-text="确认"
      >
      </van-picker>
    </van-popup>
    <van-sticky>
      <van-nav-bar
        :title="'团队' + $returnBuySel('报表')"
        @click-left="$router.push('/selfData')"
      >
        <template #left>
          <van-icon name="arrow-left" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <van-popup v-model="selectSubShow" position="bottom">
      <van-picker
        title="选择下线"
        show-toolbar
        :columns="subList"
        @confirm="onConfirm"
        @cancel="selectSubShow = false"
        position="bottom"
      />
    </van-popup>
    <!-- top -->
    <div class="wrap">
      <!-- :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id ? '/teamBg.png' : '/teamBg.png') +
          ')',
      }" -->
      <div class="contentWrap">
        <div class="selectWrapMethod">
          <div @click="$router.push('myReport')">个人报表</div>
          <div class="active">团队报表</div>
        </div>
        <div
          style="display: flex; justify-content: flex-end; margin-bottom: 15px"
        >
          <van-checkbox class="ml-auto" v-model="showMethod"
            >电脑模式</van-checkbox
          >
        </div>
        <div class="selectWrap">
          <div
            class="dateWrap"
            :style="{ width: selfData.hide_team ? '100%' : '' }"
          >
            <date-range-picker
              style="width: 100%"
              class="timePick teamReport"
              ref="picker"
              opens="center"
              :timePicker="false"
              v-model="dates"
              :locale-data="local"
              :ranges="false"
              :maxDate="getGMT_8(new Date())"
              :minDate="minDate()"
              :autoApply="true"
            >
              <template
                slot="input"
                v-if="dates.startDate == ''"
                style="min-width: 350px; padding-left: 15px"
              >
                <span style="min-width: 350px; padding-left: 15px">
                  {{ "请输入日期时间" }}
                </span>
              </template>
            </date-range-picker>
          </div>
          <div
            class="iosSelect"
            @click="selectSubShow = true"
            v-if="!selfData.hide_team"
          >
            <span>{{ selectSub.text || "选择下线" }}</span>
            <van-icon name="arrow-down" />
          </div>
        </div>
        <div
          class="iosSelect"
          @click="selectBSubShow = true"
          style="width: 100%; margin-bottom: 15px"
        >
          <span>{{ selectBSub.text || "选择渠道" }}</span>
          <van-icon name="arrow-down" />
        </div>
        <div v-if="showMethod" style="padding-bottom: 50px">
          <h2 class="title">个人报表</h2>
          <el-table
            stripe
            :data="[except.index]"
            border
            style="width: 100%; margin-top: 18px"
          >
            <el-table-column
              header-align="center"
              align="center"
              prop="member_id"
              label="会员号"
            >
              <template slot-scope="scope">
                <div>
                  <p style="cursor: pointer" @click="showLower(scope.row)">
                    {{ scope.row.member_id }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="200px"
              header-align="center"
              align="center"
              prop="name"
              label="名称"
            >
            </el-table-column>
            <el-table-column
              width="200px"
              label="直属交易量"
              header-align="center"
              align="right"
              prop="trans_amount_subs"
            >
            </el-table-column>
            <el-table-column
              width="200px"
              label="交易量"
              header-align="center"
              align="right"
              prop="trans_amount"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="right"
              prop="withdraw_amount"
              label="提现"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="right"
              prop="bonus_payment"
              label="进货红利"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="right"
              prop="bonus_trans"
              label="出货红利"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="today_p_count"
              label="当日进货数量"
              width="150px"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="right"
              prop="bonus_trans_group"
              label="下级返利"
              width="160px"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              label="USDT充值"
              width="150px"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.deposit_usdt || 0 }} /
                  {{ scope.row.deposit_usdt_r || 0 }}
                </div>
              </template>
            </el-table-column>
          </el-table>

          <h2 class="title">团队报表</h2>
          <el-table
            stripe
            :data="reportData"
            border
            style="width: 100%; margin-top: 18px"
          >
            <el-table-column
              header-align="center"
              align="center"
              label="会员号"
            >
              <template slot-scope="scope">
                <div>
                  <p style="cursor: pointer" @click="showLower(scope.row)">
                    {{ scope.row.member_id }}
                  </p>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="name"
              label="名称"
            >
            </el-table-column>
            <el-table-column
              label="当日活跃人数"
              header-align="center"
              align="center"
              prop="today_active_count"
            >
            </el-table-column>
            <el-table-column
              width="200px"
              label="个人交易量"
              header-align="center"
              align="right"
              prop="trans_amount_ps"
            >
            </el-table-column>
            <el-table-column
              width="200px"
              label="直属交易量"
              header-align="center"
              align="right"
              prop="trans_amount_subs"
            >
            </el-table-column>
            <el-table-column
              width="200px"
              label="交易量"
              header-align="center"
              align="right"
              prop="trans_amount"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="right"
              prop="withdraw_amount"
              label="提现"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="right"
              prop="bonus_payment"
              label="进货红利"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="right"
              prop="bonus_trans"
              label="出货红利"
            >
            </el-table-column>
            <el-table-column
              header-align="center"
              align="center"
              prop="today_p_count"
              label="当日进货数量"
              width="150px"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="cardWrap" v-else>
          <div class="cardBox" v-if="!currentMemberId">
            <p class="title">
              {{ selfData.name }} ({{ selfData.username }}) 团队{{
                $returnBuySel("报表")
              }}
            </p>

            <div class="content">
              <div class="box">
                <p>交易量</p>
                <p class="balance">￥{{ currentEx.trans_amount }}</p>
              </div>
              <div class="box" v-if="!selfData.hide_team">
                <p>个人交易量</p>
                <p class="balance">￥{{ currentEx.trans_amount_ps }}</p>
              </div>
              <div class="box">
                <p>直属交易量</p>
                <p class="balance">￥{{ currentEx.trans_amount_subs }}</p>
              </div>
              <div class="box">
                <p>提现</p>
                <p class="balance">￥{{ currentEx.withdraw_amount }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("进货") }}红利</p>
                <p class="balance">￥{{ currentEx.bonus_payment }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("出货") }}红利</p>
                <p class="balance">￥{{ currentEx.bonus_trans }}</p>
              </div>
              <div class="box">
                <p>当日{{ $returnBuySel("进货") }}数量</p>
                <p class="balance">{{ currentEx.today_p_count }}</p>
              </div>
              <div class="box">
                <p>USDT充值</p>
                <p class="balance">
                  {{ currentEx.deposit_usdt }}/{{ currentEx.deposit_usdt_r }}
                </p>
              </div>
              <div class="box">
                <p>当日操作人数</p>
                <p class="balance">{{ currentEx.today_active_count }}</p>
              </div>

              <div class="box"></div>
            </div>
          </div>
          <div class="cardBox" v-else>
            <p class="title">
              {{ currentEx.name }} ({{ currentEx.username }}) 团队{{
                $returnBuySel("报表")
              }}
            </p>
            <div class="content">
              <div class="box">
                <p>交易量</p>
                <p class="balance">￥{{ currentEx.trans_amount }}</p>
              </div>
              <div class="box">
                <p>个人交易量</p>
                <p class="balance">￥{{ currentEx.trans_amount_ps }}</p>
              </div>
              <div class="box">
                <p>直属交易量</p>
                <p class="balance">￥{{ currentEx.trans_amount_subs }}</p>
              </div>
              <div class="box">
                <p>提现</p>
                <p class="balance">￥{{ currentEx.withdraw_amount }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("进货") }}红利</p>
                <p class="balance">￥{{ currentEx.bonus_payment }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("出货") }}红利</p>
                <p class="balance">￥{{ currentEx.bonus_trans }}</p>
              </div>
              <div class="box">
                <p>当日{{ $returnBuySel("进货") }}数量</p>
                <p class="balance">{{ currentEx.today_p_count }}</p>
              </div>

              <div class="box">
                <p>当日操作人数</p>
                <p class="balance">{{ currentEx.today_active_count }}</p>
              </div>
              <div class="box"></div>
            </div>
          </div>
          <!-- <van-divider
            style="width: 100%; color: white"
            v-if="!selfData.hide_team"
            >下级盈利{{ $returnBuySel("报表") }}</van-divider
          > -->
          <div class="cardBox" v-if="except.index && !selfData.hide_team">
            <p class="title">
              {{ except.index.name }} ({{ except.index.username }}) 个人{{
                $returnBuySel("报表")
              }}
            </p>
            <div class="content">
              <div class="box">
                <p>交易量</p>
                <p class="balance">￥{{ except.index.trans_amount }}</p>
              </div>
              <div class="box">
                <p>直属交易量</p>
                <p class="balance">￥{{ except.index.trans_amount_subs }}</p>
              </div>
              <div class="box">
                <p>提现</p>
                <p class="balance">￥{{ except.index.withdraw_amount }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("进货") }}红利</p>
                <p class="balance">￥{{ except.index.bonus_payment }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("出货") }}红利</p>
                <p class="balance">￥{{ except.index.bonus_trans }}</p>
              </div>
              <div class="box">
                <p>当日{{ $returnBuySel("进货") }}数量</p>
                <p class="balance">{{ except.index.today_p_count }}</p>
              </div>
              <div class="box">
                <p>下级返利</p>
                <p class="balance">￥{{ except.index.bonus_trans_group }}</p>
              </div>
              <div class="box">
                <p>USDT充值</p>
                <p class="balance">
                  {{ except.index.deposit_usdt }}/{{
                    except.index.deposit_usdt_r
                  }}
                </p>
              </div>
              <div class="box">
                <p>当日操作人数</p>
                <p class="balance">{{ except.index.today_active_count }}</p>
              </div>
              <div class="box"></div>
            </div>
          </div>
          <div
            class="cardBox"
            v-for="report in reportData"
            :key="report.member_id"
            v-show="
              report.member_id != selfData.member_id &&
              report.member_id != selectSub.member_id &&
              report.member_id != currentMemberId
            "
          >
            <p class="title" @click="showLower(report)">
              <span>
                {{ report.name }} ({{ report.username }})团队{{
                  $returnBuySel("报表")
                }}
              </span>
              <van-icon
                v-if="
                  report.member_id != selfData.member_id &&
                  report.member_id != selectSub.member_id
                "
                name="arrow"
              />
            </p>
            <div class="content">
              <div class="box">
                <p>交易量</p>
                <p class="balance">￥{{ report.trans_amount }}</p>
              </div>
              <div class="box">
                <p>个人交易量</p>
                <p class="balance">￥{{ report.trans_amount_ps }}</p>
              </div>
              <div class="box">
                <p>直属交易量</p>
                <p class="balance">￥{{ report.trans_amount_subs }}</p>
              </div>
              <div class="box">
                <p>提现</p>
                <p class="balance">￥{{ report.withdraw_amount }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("进货") }}红利</p>
                <p class="balance">￥{{ report.bonus_payment }}</p>
              </div>
              <div class="box">
                <p>{{ $returnBuySel("出货") }}红利</p>
                <p class="balance">￥{{ report.bonus_trans }}</p>
              </div>
              <div class="box">
                <p>当日{{ $returnBuySel("进货") }}数量</p>
                <p class="balance">{{ report.today_p_count }}</p>
              </div>
              <div class="box">
                <p>USDT充值</p>
                <p class="balance">
                  {{ report.deposit_usdt }}/{{ report.deposit_usdt_r }}
                </p>
              </div>
              <div class="box">
                <p>当日操作人数</p>
                <p class="balance">{{ report.today_active_count }}</p>
              </div>
              <div class="box"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <van-pagination v-model="nowPage" :page-count="paginate.total_page" /> -->
    <foot />
  </div>
</template>
<script>
import { mapState } from "vuex";
import foot from "../../../components/footer";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: { foot, DateRangePicker },
  data() {
    return {
      showMethod: false,
      currentEx: {},
      selectSubShow: false,
      selectSub: {},
      subList: [],
      local: {
        direction: "ltr",
        format: "yyyy-mm-dd",
        separator: " - ",
        applyLabel: "确认",
        weekLabel: "W",
        cancelLabel: "取消",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["日", "一", "二", "三", "四", "五", "六"],
        monthNames: [
          "01月",
          "02月",
          "03月",
          "04月",
          "05月",
          "06月",
          "07月",
          "08月",
          "09月",
          "10月",
          "11月",
          "12月",
        ],
        firstDay: 0,
      },
      except: {},
      reportData: [],
      nowPage: 1,
      currentMemberId: "",
      currentReport: {},
      inSearchLower: false,
      paginate: {},
      currentDate: new Date(2021, 0, 17),
      startDate: "",
      endDate: "",
      showDatePicker: false,
      currentDatePicker: "",
      dates: { startDate: "", endDate: "" },
      saveData: {},
      current_page: 1,
      next_page: false,
      selectBSubShow: false,
      selectBSub: "",
    };
  },
  computed: {
    ...mapState(["selfData"]),
  },
  watch: {
    selectBSub() {
      this.getReport();
    },
    selectSub(e) {
      if (e.member_id && !e.selectAll) {
        this.showLower(e);
      } else {
        this.getReport();
      }
    },
    dates() {
      this.getReport();
    },
    selfData() {
      this.getReport();
      if (!this.selfData.hide_team) {
        this.$http.get(`/mobile/subs`).then((res) => {
          if (res.data.code == 0) {
            let subList = [];
            subList.push({
              id: this.selfData.member_id,
              member_id: this.selfData.member_id,
              text: "全部",
              selectAll: true,
            });
            Object.keys(res.data.data).forEach((e) => {
              subList.push({ member_id: e, id: e, text: res.data.data[e] });
            });
            this.subList = subList;
          }
        });
      }
    },
  },
  methods: {
    selectBank(e) {
      this.selectBSub = e;
      this.selectBSubShow = false;
    },
    getGMT_8(timestamp) {
      const date = new Date(timestamp);
      const GMT_8 = date.getHours() + (8 + date.getTimezoneOffset() / 60);
      date.setHours(GMT_8);
      return date;
    },
    minDate() {
      return this.getGMT_8(new Date(new Date().getTime() - 86400000 * 2));
    },
    onConfirm(e) {
      this.selectSub = e;
      this.selectSubShow = false;
    },
    showLower(report) {
      if (report.member_id == this.selfData.member_id) return;
      if (report.member_id == this.currentMemberId && this.nowPage != 1) return;
      this.currentReport = JSON.parse(JSON.stringify(report));
      this.currentMemberId = report.member_id;
      this.currentEx = JSON.parse(JSON.stringify(report));
      this.inSearchLower = true;
      this.nowPage = 1;
      this.getReport(report);
    },
    getReport(report) {
      let getApi = "";
      this.currentReport = report;
      if (report) {
        getApi = `/mobile/report/${report.member_id}`;
      } else {
        getApi = `/mobile/report`;
      }
      let params = {
        page: this.nowPage,
        start: this.$returnTime(this.dates.startDate),
        end: this.$returnTime(this.dates.endDate),
      };
      let sendData = {};
      Object.keys(params).forEach((e) => {
        if (String(params[e]) != "") {
          sendData[e] = params[e];
          this.saveData[e] = params[e];
        }
      });

      if (this.selectBSub.value || this.selectBSub.value === 0) {
        sendData.channel = this.selectBSub.value;
      }
      this.$http.get(getApi, { params: sendData }).then((res) => {
        if (res.data.code == 0) {
          let data = JSON.parse(JSON.stringify(res.data));
          this.except = data.except;
          this.reportData = data.data;
          if (this.selfData.hide_team) {
            this.currentEx = res.data.except.index;
          } else {
            if (this.selectSub.member_id) {
              data.data.forEach((e) => {
                if (e.member_id == this.selectSub.member_id) {
                  this.currentEx = e;
                }
              });
            } else {
              data.data.forEach((e) => {
                if (e.member_id == this.selfData.member_id) {
                  this.currentEx = e;
                }
              });
            }
          }
          this.inSearchLower = false;
        }
      });
    },
    // scrollPage() {
    //   if (window.scrollY + window.screen.height >= document.body.scrollHeight) {
    //     if (this.next_page) {
    //       this.nowPage += 1;
    //       this.getReport();
    //     }
    //   }
    // },
  },
  created() {
    if (this.selfData.member_id) {
      this.getReport();
      if (!this.selfData.hide_team) {
        this.$http.get(`/mobile/subs`).then((res) => {
          if (res.data.code == 0) {
            let subList = [];
            subList.push({
              id: this.selfData.member_id,
              member_id: this.selfData.member_id,
              text: "全部",
              selectAll: true,
            });
            Object.keys(res.data.data).forEach((e) => {
              subList.push({ member_id: e, id: e, text: res.data.data[e] });
            });
            this.subList = subList;
          }
        });
      }
    }
  },
  // mounted() {
  //   window.addEventListener("scroll", this.scrollPage);
  // },
  // destroyed() {
  //   window.removeEventListener("scroll", this.scrollPage);
  // },
};
</script>

<style lang="scss" scoped>
.wrap {
  background-color: rgba(241, 245, 254, 1);
  min-height: 100vh;
  font-size: 14px;
  display: flex;
  justify-content: center;
  padding-bottom: 110px;
}
.contentWrap {
  // max-width: 355px;
  width: 90%;
}
.selectWrap {
  display: flex;
  margin-bottom: 14px;
  .dateWrap {
    width: 60%;
    margin-right: 7.5px;
  }
  .iosSelect {
    width: 40%;
    margin-left: 7.5px;
  }
}
.cardWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 15px 0;
  .cardBox {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    .title {
      font-weight: bold;
      font-size: 15px;
      width: 100%;
      position: relative;
      margin: 10px 0;
      padding-left: 10px;
      margin-left: 40px;
      padding-top: 10px;
      &::before {
        content: "";
        position: absolute;
        height: 18px;
        left: 0;
        width: 4px;
        background-color: #4065f0;
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .box {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        .balance {
          font-weight: bold;
        }
        p {
          margin: 0;
          // padding: 5px;
          &:first-child {
            color: #999999;
          }
        }
      }
    }
  }
  b {
    width: 140px;
    height: 0;
  }
}

.selectWrapMethod {
  width: 100%;
  display: flex;
  background-color: #4065f0;
  color: white;
  border-radius: 50px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 15px;
  div {
    width: 50%;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      margin: 5px;
      background: white;
      border-radius: 50px;
      color: #4065f0;
      font-weight: bold;
    }
  }
}
</style>
